import Rails from '@rails/ujs';
const cartForm = () => {

    $("#cart-form").on("ajax:success", function (event) {
        let detail = event.detail;
        let data = detail[0], status = detail[1], xhr = detail[2];
        if ($('#success-popup').length) {
            $.magnificPopup.open({
                items: {
                    src: '#success-popup'
                },
                type: 'inline'
            });
        }
        Shop.fetch_cart();
    }).on("ajax:error", function (event) {
        console.log("error")
    });


    const $updateForm = $('#update-js-form')
    if ($updateForm.is("*")) {
        function changeCounter(selector, quantity = 0) {
            $updateForm.on("click", selector, function (e) {
                e.preventDefault();
                let $inputQuantity = $(this).closest(".line-item").find("input.line_item_quantity");
                if (quantity) {
                    $inputQuantity.val(+$inputQuantity.val() + quantity)
                } else {
                    $inputQuantity.val(0)
                }
                let elem = document.getElementById('update-cart');
                Rails.fire(elem, 'submit');
            });
        }

        changeCounter(' a.delete', 0);
        changeCounter('a.line-item-plus', 1);
        changeCounter('a.line-item-minus', -1)
    }
};

Shop.fetch_cart = function(cartLinkUrl) {
    Shop.ajax({
        url: cartLinkUrl || Shop.pathFor("cart_link"),
        success: function(data) {
            $("#link-to-cart").html(data);
        }
    });
};

export default cartForm
