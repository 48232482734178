function initCdek() {
    const $btnCdek = $('.js-btn-cdek')
    if (!$btnCdek.length) return
    $btnCdek.on('mfpOpen', function(e /*, params */) {
        function onChoose(wat) {
            try {
                if (wat.price < 1) {
                    console.log(wat.price)
                    alert('В данный момент невозможно узнать стоимость доставки')
                    return
                }
                document.querySelector('.cdek-table').style.display = 'block'
                document.getElementById('address-cdek').innerHTML = wat.cityName + ', ' + wat.PVZ.Address;
                document.getElementById('workschedule-cdek').innerHTML = wat.PVZ.WorkTime;
                document.getElementById('phone-cdek').innerHTML = wat.PVZ.Phone;
                document.getElementById('period-cdek').innerHTML = wat.term;
                $('.js-form-item-cdek').removeClass('form-item')
                window.validationAddressCart()
                const $inputCdek = $('.js-input-cdek')
                $inputCdek.val(wat.id)
                $inputCdek.closest('.radio-wrap').attr('data-delivery-cost', wat.price)
                window.changePrices(wat.price)
                $.magnificPopup.close();
            } catch (e) {
                console.error(e)
            }
        }
        const deliveryRow = document.querySelector('.js-delivery-row')
        if (!deliveryRow) {
            throw new Error('Ошибка получения данных по корзине')
        }
        try {
            var ourWidjet = new ISDEKWidjet ({
                hidedelt: true,
                defaultCity: 'Санкт-Петербург', //какой город отображается по умолчанию
                cityFrom: 'Санкт-Петербург', // из какого города будет идти доставка
                country: 'Россия', // можно выбрать страну, для которой отображать список ПВЗ
                link: 'forpvz', // id элемента страницы, в который будет вписан виджет
                path: 'https://widget.cdek.ru/widget/scripts/', //директория с библиотеками виджета
                servicepath: '/api/v1/cdek', //ссылка на файл service.php на вашем сайте
                //apikey: 'c87ee04f-33f2-449b-8084-0be2270f4a6b', // ключ для корректной работы Яндекс.Карт, получить необходимо тутб
                goods: [{
                    length: +deliveryRow.dataset.depth,  //length  это depth
                    width: +deliveryRow.dataset.width,
                    height: +deliveryRow.dataset.height,
                    weight: +deliveryRow.dataset.weight,
                    price: +deliveryRow.dataset.price,
                }],
                onChoose: onChoose,
            });
        } catch (e) {
            console.error(e)
        }
    });
}

export default initCdek
