import 'slick-carousel'

function initPhotoSlider(counter = 0) {
  const $photoSlider = $('.photo-slider')
  if (!$photoSlider.length) {
    return
  }
  const $sliderItem = $('.slider__item')

  if ($photoSlider.innerWidth() > 1000 || $sliderItem.innerWidth() === 0) {
    if (counter > 100) {
      throw new Error('Ошибка слайдера')
    }
    setTimeout(() => {
      initPhotoSlider(counter + 1)
    }, 100)
    return;
  }

  $photoSlider.slick({
    dots: false,
    infinite: true,
    speed: 0,
    fade: false,
    cssEase: 'linear'
  });

  document.addEventListener('turbolinks:before-cache', () => $photoSlider.slick('unslick'))
}

function initThumbnails() {
  const thumbnails = document.querySelector('.js-thumbnails')
  if (!thumbnails) {
    return
  }
  const $photoSlider = $('.photo-slider')
  if (!$photoSlider.length) return;
  const CLASS_THUMBNAIL_ITEM = 'js-thumbnail'

  function onThumbnailsMouseOver(e) {
    e.preventDefault()
    const $target = $(e.target)
    const $thumbnailItem = $target.closest(`.${CLASS_THUMBNAIL_ITEM}`)
    if (!$thumbnailItem.length) return
    try {
      $photoSlider.slick('slickGoTo', $thumbnailItem.data('slide'))
    } catch (e) {
      console.error(e)
    }
  }
  thumbnails.addEventListener('mouseover', onThumbnailsMouseOver)
}
function initThumbnailsArrow() {
  const arrowTop = document.querySelector('.js-arrow-top')
  const arrowBottom = document.querySelector('.js-arrow-bottom')
  const inner = document.querySelector('.js-thumbnail-inner')
  const thumbnailAll = document.querySelectorAll('.js-thumbnail')
  if (!arrowTop || !arrowBottom || !inner || !thumbnailAll.length) {
    return
  }
  const PHOTO_MAX = 4
  const TRANSFORM_SIZE = 106
  const CLASS_ARROW_DISABLED = 'arrow_disabled'
  let transformNumber = 0
  const transformMax = thumbnailAll.length - PHOTO_MAX

  function changeTranslate() {
    inner.style.transform = `translateY(${transformNumber * TRANSFORM_SIZE}px)`
    if (!transformNumber) {
      arrowTop.classList.add(CLASS_ARROW_DISABLED)
    } else {
      arrowTop.classList.remove(CLASS_ARROW_DISABLED)
    }
    if (Math.abs(transformNumber) === transformMax) {
      arrowBottom.classList.add(CLASS_ARROW_DISABLED)
    } else {
      arrowBottom.classList.remove(CLASS_ARROW_DISABLED)
    }
  }
  function onArrowTopClick(e) {
    e.preventDefault()
    transformNumber += 1
    changeTranslate()
  }
  function onArrowBottomClick(e) {
    e.preventDefault()
    transformNumber += -1
    changeTranslate()
  }
  arrowTop.addEventListener('click', onArrowTopClick)
  arrowBottom.addEventListener('click', onArrowBottomClick)
}

function initSliders() {
  initPhotoSlider()
  initThumbnails()
  initThumbnailsArrow()
}

export default initSliders
