import './shop.js.erb'
import './polyfills'
import FlashNotice from './flash_notice'
import popupInit from './popupInit'
import validationFormInit from './validationFormInit'
import initSocialLikes from './socialLikes'
import initNotification from './notifications'
import initSliders from './photo-slider'
import cartForm from './cart_form'
import initBoxberry from "./boxberry";
import initCdek from "./cdek";

document.addEventListener('turbolinks:load', function() {
  new FlashNotice()
  initSocialLikes()
  popupInit()
  validationFormInit()
  initNotification()
  initSliders()
  cartForm()
  initBoxberry()
  initCdek()
})


