import Rails from '@rails/ujs';

require("turbolinks").start();
// require("channels");

var jQuery = require("jquery")
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
require('bootstrap');

import './site/headerMenu'
import './site/init'
Rails.start();
