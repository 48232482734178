window.Shop = {};


Shop.mountedAt = function() {
    return "/";
};

Shop.pathFor = function(path) {
    var locationOrigin;
    locationOrigin = (window.location.protocol + "//" + window.location.hostname) + (window.location.port ? ":" + window.location.port : "");
    return locationOrigin + Shop.mountedAt() + path;
};

Shop.ajax = function(url, options) {
    if (typeof url === "object") {
        options = url;
        url = undefined;
    }
    options = options || {};
    options = $.extend(options, {
        headers: {
            'Authorization': 'Bearer ' + Shop.api_key
        }
    });
    return $.ajax(url, options);
};
