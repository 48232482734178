const axios = require('axios').default;

function initBoxberryPoints() {
    const btnBoxberry = document.querySelector('.js-btn-boxberry')
    if (!btnBoxberry) return

    //load boxberry.js
    !function () {
        var t = document.createElement("script");
        t.type = "text/javascript";
        t.async = !0;
        t.src = "https://points.boxberry.ru/js/boxberry.js";
        document.head.appendChild(t)
    }();

    function callback_function(result) {
        try {
            if (result.price < 1) {
                alert('В данный момент невозможно узнать стоимость доставки.')
                return
            }
            document.querySelector('.boxberry-table').style.display = 'block'
            document.getElementById('address').innerHTML = result.address;
            document.getElementById('workschedule').innerHTML = result.workschedule;
            document.getElementById('phone').innerHTML = result.phone;
            document.getElementById('period').innerHTML = result.period;
            $('.js-form-item-boxberry').removeClass('form-item')
            window.validationAddressCart()
            const $inputPvz = $('.js-input-pvz')
            $inputPvz.val(result.id)
            $inputPvz.closest('.radio-wrap').attr('data-delivery-cost', result.price)
            window.changePrices(result.price)
        } catch (e) {
            console.error(e)
        }
    }

    function onBtnBoxberryClick() {
        const deliveryRow = document.querySelector('.js-delivery-row')
        if (!deliveryRow) {
            throw new Error('Ошибка получения данных по корзине')
        }
        try {
            const api_token = '1$oo2ukD-hL86gpYu0rW934Ze0p-IGi9Pc'
            const custom_city = 'Санкт-Петербург'
            const target_start = ''
            const ordersum = +deliveryRow.dataset.price
            const weight = +deliveryRow.dataset.weight
            const paysum = 0
            const height = +deliveryRow.dataset.height
            const width = +deliveryRow.dataset.width
            const depth = +deliveryRow.dataset.depth
            boxberry.open(callback_function, api_token, custom_city, target_start, ordersum, weight, paysum, height, width, depth );
        } catch (e) {
            console.error(e)
        }

    }

    btnBoxberry.addEventListener('click', onBtnBoxberryClick)

}
function initBoxberry() {
    initBoxberryPoints()
}

export default initBoxberry
